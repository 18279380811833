import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "682px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/314063330d919a003172d3faac9870a0/54e8a/swap.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB11cRkuDf/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERAgMS/9oACAEBAAEFAlGYUFzgvae7T//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAeEAACAgAHAAAAAAAAAAAAAAAAAQIREBIhIjGBkf/aAAgBAQAGPwJpNm7Ts5l6XJrKKo3h/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIXExQWH/2gAIAQEAAT8hsMk6pIoRJ9vAFEiXpxmX4juocT//2gAMAwEAAgADAAAAECs//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/EAxjL//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAgEBPxC4p//EABwQAAMBAAIDAAAAAAAAAAAAAAERIQAxcUFRgf/aAAgBAQABPxC3xvkOzMXJPFEXzIjD0MIqUgRLMKDAKbdZ0YRXG//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "swap",
            "title": "swap",
            "src": "/static/314063330d919a003172d3faac9870a0/54e8a/swap.jpg",
            "srcSet": ["/static/314063330d919a003172d3faac9870a0/f93b5/swap.jpg 300w", "/static/314063330d919a003172d3faac9870a0/b4294/swap.jpg 600w", "/static/314063330d919a003172d3faac9870a0/54e8a/swap.jpg 682w"],
            "sizes": "(max-width: 682px) 100vw, 682px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Token swaps in Materia are a way to trade one token for another, you will be able to exchange `}<a parentName="p" {...{
        "href": "https://ethitem.com/"
      }}>{`ITEMs`}</a>{` ERC20 interoperable. `}</p>
    <p>{`For end-users, swapping is intuitive: a user picks an input token and an output token. They specify an input amount, and the protocol calculates how much of the output token they’ll receive. They then execute the swap with one click, receiving the output token in their wallet immediately.`}</p>
    <p>{`While swapping you will be able to see the tipology of the your tokens (ERC20 or ITEMs) and behave accordingy. ERC20 Tokens will have a higher swap fee price due to the need of wrapping them into ITEMs. As stated in the `}<a parentName="p" {...{
        "href": "/docs/materia/protocol-overview"
      }}>{`Protocol Overview`}</a>{`, every pair in Materia is actually composed of an ITEM (its ERC20 Interoperable reperesentation precisely) and WUSD.`}</p>
    <p>{`In this guide, we’ll look at what happens during a swap at the protocol level in order to gain a deeper understanding of how Materia works.`}</p>
    <p>{`Swaps in Materia are different from trades on traditional platforms. Materia does not use an order book to represent liquidity or determine prices. Materia uses an automated market maker mechanism to provide instant feedback on rates and slippage.`}</p>
    <p>{`As we learned in `}<a parentName="p" {...{
        "href": "/docs/materia/protocol-overview"
      }}>{`Protocol Overview`}</a>{`, each pair on Materia is actually underpinned by a liquidity pool. Liquidity pools are smart contracts that hold balances of two unique tokens and enforces rules around depositing and withdrawing them.`}</p>
    <p>{`This rule is the `}<a parentName="p" {...{
        "href": "/docs/materia/protocol-overview/glossary#constant-product-formula"
      }}>{`constant product formula`}</a>{`. When either token is withdrawn (purchased), a proportional amount of the other must be deposited (sold), in order to maintain the constant.`}</p>
    <h2 {...{
      "id": "anatomy-of-a-swap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#anatomy-of-a-swap",
        "aria-label": "anatomy of a swap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Anatomy of a swap`}</h2>
    <p>{`At the most basic level, all swaps in Materia happen within a single function, aptly named `}<inlineCode parentName="p">{`swap`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swap(uint amount0Out, uint amount1Out, address to, bytes calldata data);
`}</code></pre>
    <h1 {...{
      "id": "receiving-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#receiving-tokens",
        "aria-label": "receiving tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Receiving tokens`}</h1>
    <p>{`As is probably clear from the function signature, Materia requires `}<inlineCode parentName="p">{`swap`}</inlineCode>{` callers to `}<em parentName="p">{`specify how many output tokens they would like to receive`}</em>{` via the `}<inlineCode parentName="p">{`amount{0,1}Out`}</inlineCode>{` parameters, which correspond to the desired amount of `}<inlineCode parentName="p">{`token{0,1}`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "sending-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#sending-tokens",
        "aria-label": "sending tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending Tokens`}</h1>
    <p>{`What’s not as clear is how Materia `}<em parentName="p">{`receives`}</em>{` tokens as payment for the swap. Typically, smart contracts which need tokens to perform some functionality require callers to first make an approval on the token contract, then call a function that in turn calls transferFrom on the token contract. This is `}<em parentName="p">{`not`}</em>{` how pairs accept tokens. Instead, pairs check their token balances at the `}<em parentName="p">{`end`}</em>{` of every interaction. Then, at the beginning of the `}<em parentName="p">{`next`}</em>{` interaction, current balances are differenced against the stored values to determine the amount of tokens that were sent by the current interactor. `}</p>
    <p>{`The takeaway is that `}<strong parentName="p">{`tokens must be transferred to pairs before swap is called`}</strong>{` (the one exception to this rule is `}<Link to='/docs/materia/core-concepts/flash-swaps' mdxType="Link">{`Flash Swaps`}</Link>{`). This means that to safely use the `}<inlineCode parentName="p">{`swap`}</inlineCode>{` function, it must be called from `}<em parentName="p">{`another smart contract`}</em>{`. The alternative (transferring tokens to the pair and then calling `}<inlineCode parentName="p">{`swap`}</inlineCode>{`) is not safe to do non-atomically because the sent tokens would be vulnerable to arbitrage.`}</p>
    <h1 {...{
      "id": "developer-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developer-resources",
        "aria-label": "developer resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer resources`}</h1>
    <ul>
      <li parentName="ul">{`To see how to implement token swaps in a smart contract read `}<a parentName="li" {...{
          "href": "/docs/materia/smart-contract-integration/trading-from-a-smart-contract/"
        }}>{`Trading from a smart contract`}</a>{`.`}</li>
      <li parentName="ul">{`To see how to execute a swap from an interface read `}<a parentName="li" {...{
          "href": "/docs/materia/javascript-SDK/trading/"
        }}>{`Trading (SDK)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      